// material
import { styled } from '@mui/material/styles';
// components
import Page from '../components/Page';
import { Container, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useEffect, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { agate } from 'react-syntax-highlighter/dist/esm/styles/hljs';
// ----------------------------------------------------------------------
import { trackEvent } from '../components/amplitute';

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(11)
  }
}));

const HeroSection = styled(Page)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  minHeight: '568px',
  background:
    'linear-gradient(133deg, rgba(246,249,254,1) 0%, rgba(21,101,216,0.33657212885154064) 40%, rgba(246,249,254,1) 100%)',
  h1: {
    fontSize: '48px',
    fontWeight: 700,
    color: '#072146',
    marginTop: '60px'
  },
  h4: {
    fontSize: '24px',
    fontWeight: 500,
    color: '#072146',
    maxWidth: '768px'
  },
  '.btn-container': {
    marginTop: '70px',

    '.MuiButton-containedPrimary': {
      border: '1px solid #1565D8',
      borderRadius: '4px',
      marginRight: '20px',
      width: '185px',
      padding: '13px',
      fontSize: '18px'
    },
    '.MuiButton-containedSecondary': {
      border: '1px solid #1565D8',
      borderRadius: '4px',
      background: '#fff',
      color: '#1565D8',
      width: '260px',
      padding: '13px',
      fontSize: '18px'
    }
  },
  [theme.breakpoints.down('sm')]: {
    paddingBottom: theme.spacing(2),
    h1: {
      textAlign: 'center',
      marginTop: 0,
      fontSize: '36px'
    },
    h4: {
      textAlign: 'center',
      fontSize: '20px'
    },
    '.btn-container': {
      marginTop: '50px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '.MuiButton-containedPrimary': {
        marginRight: 0,
        width: '80%',
        marginBottom: '25px'
      },
      '.MuiButton-containedSecondary': {
        width: '80%',
        marginBottom: '25px'
      }
    }
  }
}));

const PowerFullAPISection = styled(Page)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
  backgroundColor: '#F9F9F9',
  h2: {
    fontSize: '36px',
    marginBottom: '50px',
    textAlign: 'center'
  },
  '.card-container': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'strech',
    gap: '5px',
    div: {
      borderRadius: '4px',
      boxShadow: '1px 1px 4px rgb(0 0 0 / 20%)',
      width: '296px',
      padding: '50px 15px 15px 15px',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      flexShrink: 'inherit',
      textAlign: 'center',
      alignItems: 'center',
      h6: {
        fontSize: '20px',
        fontWeight: 500
      },
      p: {
        fontSize: '18px'
      }
    }
  },
  [theme.breakpoints.down('sm')]: {
    h2: {
      fontSize: '28px'
    },
    '.card-container': {
      flexDirection: 'column',
      rowGap: '25px',
      alignItems: 'center',
      div: {
        padding: '25px 15px 25px 15px'
      }
    }
  }
}));

const SimpleToIntegrateSection = styled(Page)(({ theme }) => ({
  '.MuiContainer-root': {
    display: 'flex',
    padding: '50px 0',
    '> div': {
      width: '50%',
      maxHeight: '500px',
      overflow: 'auto'
    },
    '> div:first-child': {
      color: '#fff',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      p: {
        width: '300px',
        paddingTop: '20px'
      }
    },
    h2: {
      fontSize: '36px'
    },
    pre: {
      minHeight: '425px',
      minWidth: '400px'
    }
  },
  backgroundColor: '#111827',
  '.interateImgDesk': {
    display: 'block'
  },
  '.interateImgMob': {
    display: 'none'
  },
  ul: {
    display: 'flex',
    flexDirection: 'row',
    listStyle: 'none',
    color: '#fff',
    li: {
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      borderTop: '2px solid #333333',
      borderRight: '2px solid #333333',
      borderLeft: '2px solid #333333',
      padding: '5px 30px',
      cursor: 'pointer',
      '&.active': {
        background: '#333333'
      }
    }
  },
  '.tab-content': {
    display: 'none'
  },
  '.tab-content.active': {
    display: 'block'
  },
  [theme.breakpoints.down('sm')]: {
    '.interateImgDesk': {
      display: 'none'
    },
    '.interateImgMob': {
      display: 'block'
    },
    '.MuiContainer-root': {
      flexDirection: 'column',
      pre: {
        minHeight: '425px',
        minWidth: '90%'
      },
      '>div:first-child': {
        width: '100%'
      },
      '>div:nth-child(2)': {
        width: '90%',
        marginTop: '50px'
      },
      '>div': {
        margin: 'auto'
      },
      h2: {
        fontSize: '24px'
      }
    }
  }
}));

const FlexiblePricingPlansSection = styled(Page)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  '.mobile': {
    display: 'none'
  },
  h2: {
    fontSize: '36px',
    marginBottom: '50px',
    textAlign: 'center'
  },
  '.desktop p': {
    fontSize: '22px',
    textAlign: 'center',
    maxWidth: '840px',
    margin: '0 auto 20px auto'
  },
  '.choosePlan': {
    margin: '0 auto 20px auto',
    maxWidth: '842px',
    textAlign: 'center'
  },
  h5: {
    fontSize: '22px',
    fontWeight: 700
  },
  '.subscription-section': {
    marginTop: '50px',
    marginBottom: '50px',
    p: {
      textAlign: 'left',
      maxWidth: '800px',
      fontSize: '16px'
    }
  },
  '.row': {
    display: 'flex',
    borderBottom: '1px solid #1565D8',
    height: '200px',
    '> div:first-child': {
      width: '184px',
      fontSize: '18px',
      paddingRight: '10px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    '> div:not(:first-child)': {
      width: '21%',
      padding: '0 20px',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    '.cnt-val': {
      color: '#1565D8'
    },
    '.MuiButton-root': {
      backgroundColor: '#1565D8',
      boxShadow: 'none',
      borderRadius: '4px'
    },
    'div:nth-child(1)': {
      fontWeight: 500,
      fontSize: '18px'
    },
    span: {
      fontSize: '13px',
      display: 'block',
      width: '100%'
    },
    '@media (min-width: 600px) and (max-width: 840px)': {
      p: {
        fontSize: '18px',
        margin: '0 auto 20px auto'
      },
      h5: {
        fontSize: '18px',
        fontWeight: 700
      },
      '> div:first-child': {
        width: '89px'
      },
      'div:nth-child(1)': {
        fontSize: '14px'
      }
    }
  },
  '.mob-card': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    border: '1px solid #1565D8',
    borderRadius: '6px',
    ul: {
      listStyle: 'none',
      '.MuiSvgIcon-root': {
        color: '#1565D8'
      },
      li: {
        display: 'flex',
        gap: '10px',
        lineHeight: '30px'
      }
    },
    '.MuiButton-root': {
      margin: '15px auto 0 auto',
      display: 'block',
      backgroundColor: '#1565D8',
      boxShadow: 'none',
      borderRadius: '4px'
    },
    p: {
      fontSize: '22px',
      fontWeight: 700,
      textAlign: 'center'
    },
    'div:nth-child(1)': {
      borderBottom: '1px solid #1565D8',
      paddingBottom: '15px',
      width: '85%',
      marginBottom: '30px'
    }
  },
  [theme.breakpoints.down('sm')]: {
    h2: {
      fontSize: '28px',
      marginBottom: '20px'
    },
    h5: {
      fontSize: '20px'
    },
    '.desktop': {
      display: 'none'
    },
    '.mobile': {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '20px',
      marginTop: '20px'
    },
    '.choosePlan': {
      textAlign: 'center'
    }
  }
}));

const ReadyToGetStarted = styled(Page)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
  background: '#111827',
  h2: {
    color: '#F9F9F9',
    fontSize: '36px',
    textAlign: 'center',
    marginBottom: '25px'
  },
  h5: {
    color: '#F9F9F9',
    fontSize: '22px',
    textAlign: 'center',
    fontWeight: 'normal',
    maxWidth: '840px'
  },
  '.MuiButton-root': {
    marginTop: '70px',
    fontSize: '18px',
    width: '406px',
    padding: '10px'
  },
  '.MuiContainer-root > div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  [theme.breakpoints.down('sm')]: {
    '.MuiButton-root': {
      width: '80%'
    }
  }
}));

const PageContainer = styled(Container)(({ theme }) => ({
  '.arrow': {
    '@media (max-width: 768px)': {
      display: 'none'
    }
  }
}));
// ----------------------------------------------------------------------

export default function RealTimeAPI() {
  const [tab, setTab] = useState('request');

  useEffect(() => {
    Array.from(document.getElementsByClassName('row')).forEach((elem) => {
      elem.addEventListener('mouseover', (node: any) => {
        const classNm: string = node?.target?.className || '';
        if (typeof classNm === 'string' && classNm?.includes('base-plan')) {
          Array.from(document.getElementsByClassName('base-plan')).forEach((elem2: any) => {
            elem2.style.background = 'rgba(21, 101, 216, 0.1)';
          });
          Array.from(document.getElementsByClassName('silver-plan')).forEach((elem2: any) => {
            elem2.style.background = 'none';
          });
        }
        if (typeof classNm === 'string' && classNm?.includes('silver-plan')) {
          Array.from(document.getElementsByClassName('silver-plan')).forEach((elem2: any) => {
            elem2.style.background = 'rgba(21, 101, 216, 0.1)';
          });
          Array.from(document.getElementsByClassName('base-plan')).forEach((elem2: any) => {
            elem2.style.background = 'none';
          });
          Array.from(document.getElementsByClassName('gold-plan')).forEach((elem2: any) => {
            elem2.style.background = 'none';
          });
          Array.from(document.getElementsByClassName('enterprise-plan')).forEach((elem2: any) => {
            elem2.style.background = 'none';
          });
        }
        if (typeof classNm === 'string' && classNm?.includes('gold-plan')) {
          Array.from(document.getElementsByClassName('gold-plan')).forEach((elem2: any) => {
            elem2.style.background = 'rgba(21, 101, 216, 0.1)';
          });
          Array.from(document.getElementsByClassName('base-plan')).forEach((elem2: any) => {
            elem2.style.background = 'none';
          });
          Array.from(document.getElementsByClassName('silver-plan')).forEach((elem2: any) => {
            elem2.style.background = 'none';
          });
          Array.from(document.getElementsByClassName('enterprise-plan')).forEach((elem2: any) => {
            elem2.style.background = 'none';
          });
        }
        if (typeof classNm === 'string' && classNm?.includes('enterprise-plan')) {
          Array.from(document.getElementsByClassName('enterprise-plan')).forEach((elem2: any) => {
            elem2.style.background = 'rgba(21, 101, 216, 0.1)';
          });
          Array.from(document.getElementsByClassName('base-plan')).forEach((elem2: any) => {
            elem2.style.background = 'none';
          });
          Array.from(document.getElementsByClassName('gold-plan')).forEach((elem2: any) => {
            elem2.style.background = 'none';
          });
          Array.from(document.getElementsByClassName('silver-plan')).forEach((elem2: any) => {
            elem2.style.background = 'none';
          });
        }
      });
    });
  }, []);

  const onClickViewDocs = () => {
    trackEvent('click_view_documentation', {
      ScreenName: 'API screen',
      path: window.location.href
    });
  };

  const onClickGetAPI = () => {
    trackEvent('click_get_api_key', {
      ScreenName: 'API screen',
      path: window.location.href
    });
  };

  const getAPIKeyNow = () => {
    trackEvent('click_get_your_api_key_now', {
      ScreenName: 'API screen',
      path: window.location.href
    });
  };

  return (
    <RootStyle title="Real time API">
      <HeroSection>
        <PageContainer>
          <div>
            <h1>Real-Time Trading Intelligence API</h1>
            <h4>
              Access comprehensive Corporate insider trading data and congressional trading insights
              through our TradingTown API
            </h4>
          </div>
          <div className="btn-container">
            <Button variant="contained" onClick={onClickGetAPI}>
              Get API Key
            </Button>
            <Button variant="contained" color="secondary" onClick={onClickViewDocs}>
              View Documentation
            </Button>
          </div>
        </PageContainer>
      </HeroSection>
      <PowerFullAPISection>
        <PageContainer>
          <div>
            <h2>Powerful APIs for Insider Intelligence</h2>
            <div className="card-container">
              <div>
                <img src="/static/api-page/container.png" />
                <h6>Corporate Transactions</h6>
                <p>
                  Real-time access to corporate insider buying activities with comprehensive
                  historical track records
                </p>
              </div>
              <div>
                <img src="/static/api-page/dollar.png" />
                <h6>Congress Trading</h6>
                <p>
                  Track congressional trading activities with detailed background information and
                  historical performance
                </p>
              </div>
              <div>
                <img src="/static/api-page/badge.png" />
                <h6>Daily Top Picks</h6>
                <p>Get daily ranked corporate insider buys based on our proprietary algorithm</p>
              </div>
            </div>
          </div>
        </PageContainer>
      </PowerFullAPISection>
      <SimpleToIntegrateSection>
        <PageContainer>
          <div>
            <h2>Simple to Integrate</h2>
            <p>
              Our REST API is designed for easy integration with any programming language. Get
              started in minutes with our example code.
            </p>
          </div>
          <div>
            {/* <img src="/static/api-page/integrate.png" className="interateImgDesk" />
            <img src="/static/api-page/integratemob.png" className="interateImgMob" /> */}
            <ul>
              <li
                onClick={() => setTab('request')}
                className={`${tab === 'request' ? 'active' : ''}`}
              >
                Request
              </li>
              <li
                onClick={() => setTab('response')}
                className={`${tab === 'response' ? 'active' : ''}`}
              >
                Response
              </li>
            </ul>
            <div className={`tab-content ${tab === 'request' ? 'active' : ''}`} id="request">
              <SyntaxHighlighter language="javascript" style={agate}>
                {`# API configuration
  API_KEY = 'your_api_key'
  BASE_URL = 'https://api.tradingtown.space/v1'
  params = {
      'ticker': 'TSLA',
      'limit': 20,
      'from': '2024-03-15',
      'to': '2024-12-16',
      'track_record': True,
      'apiKey': API_KEY
  }

  # Make API request to insider transactions endpoint
  response = requests.get(
      f'{BASE_URL}/stock/corporate-insider-transactions',
      params=params
  )`}
              </SyntaxHighlighter>
            </div>
            <div className={`tab-content ${tab === 'response' ? 'active' : ''}`} id="reponse">
              <SyntaxHighlighter language="javascript" style={agate}>
                {`

{
  "data": [
    {
      "name": "Schuppenhauer Eric",
      "share": 30599.81,
      "ownershipChange": "10%",
      "transactionDate": "2024-12-16",
      "transactionCode": "P",
      "transactionPricePerShare": 16.34,
      "isDirector": false,
      "isOfficer": true,
      "officerTitle": "EVP GBUL Borrow",
      "ticker": "SOFI",
      "source": "https://www.sec.gov/Archives/edgar/data/1818874/000181887424000248/wk-form4_1734388771.xml",
      "industry": "Financials",
      "detailedPerTickerStats": {
        "2W": {
          "accumTransactionWins": 14,
          "numTransactions": 16,
          "accumTransactionValue": 10216349.34,
          "accumWeightedTransactionReturns": 721245.6135827636
        },
        "1M": {
          "accumTransactionWins": 15,
          "numTransactions": 15,
          "accumTransactionValue": 10017579.97,
          "accumWeightedTransactionReturns": 781995.89
        }
      }
    },
    {
      "name": "Murti Arjun N",
      "share": 2500,
      "ownershipChange": "5%",
      "transactionDate": "2024-12-20",
      "transactionCode": "P",
      "transactionPricePerShare": 95.8699,
      "isDirector": false,
      "isOfficer": true,
      "officerTitle": "",
      "ticker": "COP",
      "source": "https://www.sec.gov/Archives/edgar/data/1425292/000106299324021091/form4.xml",
      "industry": "Energy"
    }
  ]
}
`}
              </SyntaxHighlighter>
            </div>
          </div>
        </PageContainer>
      </SimpleToIntegrateSection>
      <FlexiblePricingPlansSection>
        <PageContainer>
          <div>
            <h2>Flexible Pricing Plans</h2>
            <p className="choosePlan">
              Choose the plan that fits your needs. From individual investors to professional
              trading firms, we have options for everyone. Upgrade or downgrade anytime.
            </p>

            <div className="desktop">
              <div className="row">
                <div></div>
                <div className="base-plan">
                  <h5>Base</h5>
                  <p>$0.99/mo</p>
                  <Button variant="contained">Pick Plan</Button>
                </div>
                <div className="silver-plan">
                  <h5>Silver</h5>
                  <p>$3.99/mo</p>
                  <Button variant="contained">Pick Plan</Button>
                </div>
                <div className="gold-plan">
                  <h5>Gold</h5>
                  <p>$19.99/mo</p>
                  <Button variant="contained">Pick Plan</Button>
                </div>
                <div className="enterprise-plan">
                  <h5>Enterprise</h5>
                  <p>&nbsp;</p>
                  <Button variant="contained" href="mailto:support@tradingtown.space">
                    Contact Sales
                  </Button>
                </div>
              </div>
              <div className="row">
                <div>Complete Corporate Insider Data</div>
                <div className="cnt-val base-plan">
                  <CheckCircleOutlineIcon />
                </div>
                <div className="cnt-val silver-plan">
                  <CheckCircleOutlineIcon />
                </div>
                <div className="cnt-val gold-plan">
                  <CheckCircleOutlineIcon />
                </div>
                <div className="cnt-val enterprise-plan">
                  <CheckCircleOutlineIcon />
                </div>
              </div>
              <div className="row">
                <div>Congress Trading Data</div>
                <div className="cnt-val base-plan">
                  <CheckCircleOutlineIcon />
                </div>
                <div className="cnt-val silver-plan">
                  <CheckCircleOutlineIcon />
                </div>
                <div className="cnt-val gold-plan">
                  <CheckCircleOutlineIcon />
                </div>
                <div className="cnt-val enterprise-plan">
                  <CheckCircleOutlineIcon />
                </div>
              </div>
              <div className="row">
                <div>
                  <div>Complete Corporate Insider Data</div>
                  <span>with historical track record</span>
                </div>
                <div className="base-plan"></div>
                <div className="cnt-val silver-plan">
                  <CheckCircleOutlineIcon />
                </div>
                <div className="cnt-val gold-plan">
                  <CheckCircleOutlineIcon />
                </div>
                <div className="cnt-val enterprise-plan">
                  <CheckCircleOutlineIcon />
                </div>
              </div>
              <div className="row">
                <div>
                  <div>Complete Congress Trading Data </div>
                  <span>with background info and track record</span>
                </div>
                <div className="base-plan"></div>
                <div className="cnt-val silver-plan">
                  <CheckCircleOutlineIcon />
                </div>
                <div className="cnt-val gold-plan">
                  <CheckCircleOutlineIcon />
                </div>
                <div className="cnt-val enterprise-plan">
                  <CheckCircleOutlineIcon />
                </div>
              </div>
              <div className="row">
                <div>API Requests per Min</div>
                <div className="cnt-val base-plan">4</div>
                <div className="cnt-val silver-plan">50</div>
                <div className="cnt-val gold-plan">150</div>
                <div className="cnt-val enterprise-plan">Unlimited</div>
              </div>
              <div className="row">
                <div>
                  <div>Daily Morning Insider Buy Report</div>
                  <span>with proprietary ranking algorithm</span>
                </div>
                <div className="base-plan"></div>
                <div className="silver-plan"></div>
                <div className="cnt-val gold-plan">
                  <CheckCircleOutlineIcon />
                </div>
                <div className="cnt-val enterprise-plan">
                  <CheckCircleOutlineIcon />
                </div>
              </div>
              <div className="row">
                <div>Real-Time Live Insider and Congress Data via WebSocket </div>
                <div className="base-plan"></div>
                <div className="silver-plan"></div>
                <div className="cnt-val gold-plan">
                  <CheckCircleOutlineIcon />
                </div>
                <div className="cnt-val enterprise-plan">
                  <CheckCircleOutlineIcon />
                </div>
              </div>
            </div>
            <div className="mobile">
              <div className="mob-card">
                <div>
                  <p>Base</p>
                  <p>$0.99/mo</p>
                </div>
                <div>
                  <ul>
                    <li>
                      <CheckCircleOutlineIcon />
                      Complete Corporate Insider Data
                    </li>
                    <li>
                      <CheckCircleOutlineIcon />
                      Congress Trading Data
                    </li>
                    <li>
                      <CheckCircleOutlineIcon />4 API Requests/min
                    </li>
                  </ul>
                  <Button variant="contained">Pick Plan</Button>
                </div>
              </div>
              <div className="mob-card">
                <div>
                  <p>Silver</p>
                  <p>$3.99/mo</p>
                </div>
                <div>
                  <ul>
                    <li>
                      <CheckCircleOutlineIcon />
                      Everything in Base Plan
                    </li>
                    <li>
                      <CheckCircleOutlineIcon />
                      Corporate Insider Data with historical track record
                    </li>
                    <li>
                      <CheckCircleOutlineIcon />
                      Congress Trading Data with background info and track record
                    </li>
                    <li>
                      <CheckCircleOutlineIcon />
                      150 API Requests/min
                    </li>
                  </ul>
                  <Button variant="contained">Pick Plan</Button>
                </div>
              </div>
              <div className="mob-card">
                <div>
                  <p>Gold</p>
                  <p>$19.99/mo</p>
                </div>
                <div>
                  <ul>
                    <li>
                      <CheckCircleOutlineIcon />
                      Everything in Silver Plan
                    </li>
                    <li>
                      <CheckCircleOutlineIcon />
                      Daily Morning Insider Buy Report
                    </li>
                    <li>
                      <CheckCircleOutlineIcon />
                      Real-Time Live Insider and Congress Data via WebSocket{' '}
                    </li>
                    <li>
                      <CheckCircleOutlineIcon />
                      150 API Requests/min
                    </li>
                  </ul>
                  <Button variant="contained">Pick Plan</Button>
                </div>
              </div>
              <div className="mob-card">
                <div>
                  <p>Enterprise</p>
                  <p>Contact Us</p>
                </div>
                <div>
                  <ul>
                    <li>
                      <CheckCircleOutlineIcon />
                      Everything in Silver Plan
                    </li>
                    <li>
                      <CheckCircleOutlineIcon />
                      Daily Morning Insider Buy Report
                    </li>
                    <li>
                      <CheckCircleOutlineIcon />
                      Real-Time Live Insider and Congress Data via WebSocket{' '}
                    </li>
                    <li>
                      <CheckCircleOutlineIcon />
                      Unlimited API Requests
                    </li>
                  </ul>
                  <Button variant="contained">Contact Now</Button>
                </div>
              </div>
            </div>
          </div>
          <div className="subscription-section">
            <p>
              Subscriptions are set to auto-renew by default. You can opt out of auto-renewal at any
              time.
            </p>
            <p>All sales are final.</p>
          </div>
        </PageContainer>
      </FlexiblePricingPlansSection>
      <ReadyToGetStarted>
        <PageContainer>
          <div>
            <h2>Ready to Get Started?</h2>
            <h5>
              Join the smartest investors who use our API to track corporate insider and
              congresstional trading activities. Get real-time alerts and start making data-driven
              investment decisions today.
            </h5>
            <Button variant="contained" onClick={getAPIKeyNow}>
              Get Your API Key Now
            </Button>
          </div>
        </PageContainer>
      </ReadyToGetStarted>
    </RootStyle>
  );
}
